p {
  line-height: 25px;
}

ul,
li {
  list-style: none;
}

li {
  margin-bottom: 10px;
}
