#root {
  max-width: 800px;
}

a {
  /*color: rgb(91 137 208);*/
  color: #fff;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

.component {
  color: #fff;
  position: relative;
}

button {
  background-color: rgba(91 137 208 / 1);
  cursor: pointer;
  border: solid 1px rgb(255 255 255 / 0.6);
  padding: 10px;
  margin: 10px 0;
  color: #fff;
  display: block;
}

button:hover {
  background-color: rgba(91 137 208 / 0.5);
}

button:disabled {
  cursor: default;
  background-color: rgba(255 100 100 / 0.2);
}

/* classes for alpha release */
.alpha-hide {
  color: yellow;
}

.alpha-delete {
  color: red;
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) { 
  html { 
    transform: rotate(-90deg); 
    transform-origin: left top;
    width: 100vh; 
    overflow-x: hidden; 
    position: absolute; 
    top: 100%; 
    left: 0; 
  }
} */

#container-script #menu-button-container {
  position: sticky;
  top: 0;
}
